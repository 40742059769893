import React from "react"
import { graphql } from "gatsby"

import { Layout, SEO, Container, Questions, EmailUs } from "../components"
import styles from "./index.module.scss"
import { getContentFromAllFiles } from "../utils/content"
import { pageTypes } from "../types"

const FaqPage = ({ data, pageContext: { locale } }) => {
  const { 
    title,
    subTitle,
    description,
    questions,
    emailUs,
    qLabel,
    aLabel,
  } = getContentFromAllFiles(data, 'faq');
  return (
    <Layout locale={locale} content={getContentFromAllFiles(data, 'site')}>
      <SEO
        title={`${title} | Receiver 2`}
        description={description}
        lang={locale}
        path="/faq/"
      />
      <Container className={styles.faq}>
        <div className={styles.intro}>
          <h1>{title}</h1>
          <h4>{subTitle}</h4>
        </div>
        <Questions questions={questions} qLabel={qLabel} aLabel={aLabel} />
        <EmailUs {...emailUs} />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query faqPageQuery($locale: String) {
    allFile(filter: {relativeDirectory: {eq: $locale}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              subTitle
              qLabel
              aLabel
              questions {
                question
                answer
              }
              emailUs {
                description
                button
                address
              }
            }
            ...Site
          }
        }
      }
    }
  }
`

FaqPage.propTypes = pageTypes

export default FaqPage
